import { ApolloClient, ApolloLink, ApolloProvider, InMemoryCache } from '@apollo/client';
import { GlobalToastRegion } from '@pledge-earth/product-language';
import type { ReactNode } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { createUploadLink } from 'apollo-upload-client';

import { GoogleMapsAPIProvider } from '../components/Map/GoogleMapsAPIProvider';
import { Localization } from '../localization';
import result from '../services/graphql-public/generated';
import { storeAnonymous } from '../store/storeAnonymous';

import { FlagProvider } from './FlagProvider';

const httpLink = createUploadLink({
  // @ts-expect-error -- enabling strict mode
  uri: process.env.REACT_APP_GRAPHQL_PUBLIC_ENDPOINT,
  headers: {
    'apollo-require-preflight': 'true',
  },
});

const client = new ApolloClient({
  link: ApolloLink.from([httpLink]),
  cache: new InMemoryCache({
    possibleTypes: result.possibleTypes,
  }),
});

export function ProviderShare({ children }: { children: ReactNode }) {
  return (
    <ApolloProvider client={client}>
      <ReduxProvider store={storeAnonymous}>
        <FlagProvider isAnonymous={true}>
          {/* @ts-expect-error -- enabling strict mode */}
          <GoogleMapsAPIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_JAVASCRIPT_API_KEY || ''}>
            <Localization>
              {children}
              <GlobalToastRegion />
            </Localization>
          </GoogleMapsAPIProvider>
        </FlagProvider>
      </ReduxProvider>
    </ApolloProvider>
  );
}
